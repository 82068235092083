.space-page-container {
  width: 100%;
  margin-top: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.space-page-title-text {
  font-size: 24px;
  color: #18394b;
  letter-spacing: 0;
}

.space-page-subtitle {
  margin-bottom: 40px !important;
}

.space-step-field.error {
  color: #f10504;
}

.space-step-form {
  margin-bottom: 80px;
  margin-top: 40px;
}

.space-step-field-title {
  color: #444b54;
  font-weight: bold;
  font-size: 1em;
}
