.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-1gtu0rj-indicatorContainer {
  display: none !important;
}

.css-tlfecz-indicatorContainer {
  display: none !important;
}
.css-14ye44h-control {
  border-color: transparent !important;
}

.css-xsznh4-control {
  border-color: transparent !important;
}

.css-1cxx5lr-container {
  border: 1px solid #e3e3e3 !important;
}
.css-1uccc91-singleValue {
  padding-right: 51px !important;
  max-width: calc(90% - 8px) !important;
  font-size: 16px !important;
  font-weight: 500;
  color: #18394b !important;
}
.status .css-1uccc91-singleValue {
  color: #18394b !important;
}

.iconClean::after {
  position: absolute;
  height: auto;
  object-fit: contain;
  right: 16px;
  bottom: 10px;
  cursor: pointer;
  background-color: transparent !important;
  color: black !important;
  width: 16px !important;
  padding: 2px;
  font-size: 22px !important;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.css-g1d714-ValueContainer {
  padding: 2px 52px 0px 8px !important;
}

@media screen and (max-width: 500px) {
  .TopNavbar .css-1wa3eu0-placeholder {
    font-size: 1.3em !important;
  }
}
