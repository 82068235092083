.primary {
  background-color: #18394B !important;
}

.secondary {
  background-color: #3cc47c !important;
}

.red {
  background-color: #d80d0d !important;
}
.violet {
  background-color: #7f5495 !important;
}

.app-text-primary {
  color: #18394B !important;
}

.app-text-secondary {
  color: #3cc47c !important;
}

.app-text-red {
  color: #d80d0d !important;
}
.app-text-violet {
  color: #7f5495 !important;
}
