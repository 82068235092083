body {
  color: #373737;
}
.form-group {
  width: 100%;
}
.bigTitleHome {
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  font-size: 22px;
  color: #17284f;
  letter-spacing: 1.55px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 30px;
}

.smallTitleHome {
  font-family: "Poppins", sans-serif !important;
  font-size: 22px;
  color: #515e7b;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  max-width: 730px;
  margin: 0px auto;
  text-transform: uppercase;
}

.btn-charger-plus {
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  background: #fff;
  border-radius: 25px;
  color: #2e3c5e;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid #bec2cd;
  text-transform: uppercase;
  height: 50px;
  width: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
}

.btn-charger-plus:hover {
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  background: #1292d2;
  border-radius: 25px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border: 2px solid #1292d2;
  text-transform: uppercase;
  height: 50px;
  width: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
}

.bigTitleSlider {
  font-family: "Poppins", sans-serif !important;
  text-align: left;
  font-size: 32px;
  color: #17284f;
  letter-spacing: 1.55px;
  font-weight: 600;
  text-transform: uppercase;
}

.smallTitleSlider {
  font-family: "Poppins", sans-serif !important;
  font-size: 22px;
  color: #01afe9;
  letter-spacing: 0;
  text-align: left;
  line-height: 32px;
  max-width: 730px;
  margin: 0px auto;
  text-transform: uppercase;
}

.containerHome hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ceeaff;
  width: 30%;
}

// .containerHome {
//   height: 100vh;
//   overflow-y: scroll;
//   z-index: 2;
// }
.cardCategorie {
  background: transparent;
  // box-shadow: 0 2px 4px 1px rgba(183, 179, 179, 0.5);
  // border-radius: 8px;
  width: 329px;
  height: 270px;
  position: relative;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.cardCategorie .contentImage {
  height: 141px;
  // background-color: #2e76ab;
  // border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCategorie .titleCategorie {
  font-family: "Poppins", sans-serif !important;
  font-size: 22px;
  color: #17284f;
  letter-spacing: -0.18px;
  text-align: left;
  text-transform: uppercase;
}
.cardCategorie .descCategorie {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  color: #5d6c7e;
  letter-spacing: 0.78px;
  text-align: left;
  line-height: 13px;
  text-align: left;
  max-width: 270px;
  // margin: 5px auto;
  font-weight: 300;
}

.cardCategorie .btnPlayCategorie {
  font-family: "Poppins", sans-serif !important;
  background: #04c011;
  border-radius: 16px;
  font-weight: 500;
  width: 130px;
  height: 31px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0px auto;
  font-size: 13px;
  letter-spacing: -0.13px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  text-transform: uppercase;
  z-index: 22;
}
.cardCategorie .btnComeBack {
  font-family: "Poppins", sans-serif !important;
  background: #616775;
  border-radius: 16px;
  font-weight: 500;
  width: 165px;
  height: 31px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0px auto;
  font-size: 13px;
  letter-spacing: -0.13px;
  cursor: no-drop;
  position: absolute;
  bottom: 10px;
}

.cardCategorie .btnPlayCategorie:hover {
  font-family: "Poppins", sans-serif !important;
  background: #2e76ab;
  border: 1px solid #2e76ab;
  font-weight: 500;
  border-radius: 16px;
  width: 130px;
  height: 31px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0px auto;
  font-size: 13px;
  letter-spacing: -0.13px;
  cursor: pointer;
}

.containerCategorie {
  display: flex;
  justify-content: start;
  object-fit: contain;
  // padding: 20px 100px;
  margin: 0px auto;

  width: 1040px;
}

.creation-form {
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0 2px 4px 1px rgba(183, 179, 179, 0.5);
  border-radius: 8px;
  width: 952px;
  min-height: 363px;
  margin: 50px auto;
  padding: 20px;
  position: relative;
}
.imageQuestion {
  position: absolute;
  right: 25px;
}

.titleQuizze {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-size: 22px;
  color: #0f7cf9;
  letter-spacing: -0.18px;
}

.descQuizz {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  color: #386f8d;
  letter-spacing: 0.78px;
  line-height: 13px;
  margin-bottom: 35px;
}

.countQuizz {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  color: #202020;
  letter-spacing: 0.78px;
  line-height: 25px;
  margin-bottom: 35px;
  margin-top: 5px !important;
}

.question {
  // display: flex;
  // align-items: center;
}

.responseQuestion {
  display: flex;
  align-items: baseline;
  width: 500px;
}

.keyQuestion {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  color: #2481f9;
  margin-right: 20px;
  min-width: 85px;
}
.textQuestion {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}
.textQuestion2 {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #626262;
}
.btnResponse {
  background: #04c011;
  border-radius: 25px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  width: 130px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 11px;
  text-transform: uppercase;
}
.btnResponse:hover {
  background: #04c011;
  border-radius: 25px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  width: 130px;
  height: 44px;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
  margin-top: 11px;
}

.btnResponseDisabled {
  background: #04c011;
  border-radius: 25px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  width: 130px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 11px;
  opacity: 0.7;
  text-transform: uppercase;
}

.custom-input {
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #d7d7d7;
  border-radius: 5px !important;
  color: #000000;
  width: 342px;
  height: 44px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
}

.responseFailed {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  color: #ff0000;
  letter-spacing: -0.12px;
  text-decoration: line-through;
  display: flex;
  align-items: center;
}
.responseSuccess {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  color: #1cb705;
  letter-spacing: -0.12px;
  font-family: "Poppins", sans-serif !important;

  align-items: center;
  display: flex;
}

.responseSuccess span {
  margin-left: 4px;
}

.gameOver {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border: 2px solid #ca3132;
  border-radius: 15px;
  width: fit-content;
}

.gameWiner {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border: 2px solid #1cb707;
  border-radius: 15px;
  width: fit-content;
  position: relative;
}

.textGameOver {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-size: 15px;
  color: #ca3132;
  letter-spacing: -0.16px;
}

.textGameOver span {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-size: 16px;
  color: #1cb705;
  letter-spacing: -0.16px;
}

.textGameWiner {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-size: 16px;
  color: #1cb705;
  letter-spacing: -0.16px;
  margin-left: 110px !important;
}

.textGameWiner span {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
  color: #1cb705;
  letter-spacing: -0.16px;
}

.playAnother {
  font-family: "Poppins", sans-serif !important;
  background: #04c011;
  border-radius: 25px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  width: 400px;
  height: 44px;
  letter-spacing: -0.12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.slider-home-desktop {
  width: 100%;
  height: 350px;
  background-color: #e3f3ff;
  display: flex;
  align-items: center;
  padding: 0px 100px;
}
.slider-home-mobile {
  display: none;
}

@media screen and (max-width: 900px) {
  .creation-form {
    width: 100%;
    margin: 50px 0px;
  }

  .responseQuestion {
    display: block;
    width: 60%;
  }
  .btnResponseDisabled {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }
  .btnResponse {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }

  .btnResponseDisabled:hover {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }
  .btnResponse:hover {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }
  .countQuizz {
    font-size: 12px;
  }

  // .question {
  //   display: block;
  // }
  .custom-input {
    width: 100%;
  }
  .containerHome {
    padding: 0px 10px;
    min-height: 95vh;
  }
}

.questionDetails {
  margin-left: 50px;
  min-height: 80vh;
}

.quizzeHeader {
  display: flex;
  align-items: center;
}
.cardCategorie .textCategorie {
  margin-left: 1rem !important;
}

.iconWiner {
  width: 120px;
  position: absolute;
  bottom: -11px;
  left: -10px;
}

.backgroundImageGlobal {
  position: absolute;
  right: 0 /* Position to the right */;
  width: 60% /* Adjust as needed */;
  height: 100% /* Adjust as needed */;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.containerHome {
  margin-top: 10px !important;
  min-height: 95vh;
}

@media screen and (max-width: 1800px) {
  .backgroundImageGlobal {
    position: absolute;
    right: 0 /* Position to the right */;
    width: 80% !important /* Adjust as needed */;
    height: 100% /* Adjust as needed */;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  .slider-home-desktop {
    display: none;
  }
  .containerCategorie {
    padding: 20px 0px !important;
    margin: 0px auto !important;
    width: 90% !important;
    justify-content: start !important;
  }

  .iconWiner {
    width: 110px;
    position: absolute;
    bottom: -11px;
    left: -10px;
  }
  .textGameWiner {
    font-size: 11px;
  }

  .playAnother {
    width: 100%;
  }

  .gameWiner {
    width: 100%;
  }
  .quizzeHeader {
    display: block !important;
    text-align: center;
  }
  .questionDetails {
    margin: 0px auto !important;
  }
  .slider-home-mobile {
    display: block;
    width: 100%;
    height: 290px;
    background-color: #e3f3ff;
    padding-top: 10px;
  }
  .bigTitleSlider {
    margin-top: 20px;
    text-align: center;
    font-size: 28px;
  }

  .responseQuestion {
    display: block;
    width: 100%;
  }
  .btnResponseDisabled {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }
  .btnResponse {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }

  .btnResponseDisabled:hover {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }

  .cardCategorie .btnComeBack {
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .btnResponse:hover {
    margin: 20px auto;
    margin-left: 0px !important;
    width: 100%;
  }
  .smallTitleSlider {
    text-align: center;
  }

  .bigTitleHome {
    font-family: "Poppins", sans-serif !important;
    text-align: center;
    font-size: 16px;
    color: #17284f;
    letter-spacing: 1.55px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 30px;
  }
  .containerHome {
    margin-top: 10px !important;
    min-height: 95vh;
  }

  .cardCategorie {
    background: transparent;
    // box-shadow: 0 2px 4px 1px rgba(183, 179, 179, 0.5);
    // border-radius: 8px;
    width: 100%;
    height: 270px;
    position: relative;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin: 0px !important;
    margin-top: 30px !important;
  }

  .cardCategorie .textCategorie {
    margin-left: 0rem !important;
  }

  .cardCategorie .titleCategorie {
    font-family: "Poppins", sans-serif !important;
    font-size: 22px;
    color: #17284f;
    letter-spacing: -0.18px;
    text-align: center;
    text-transform: uppercase;
  }

  .cardCategorie .descCategorie {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    color: #5d6c7e;
    letter-spacing: 0.78px;
    text-align: left;
    line-height: 13px;
    text-align: center;
    max-width: 100%;
    // margin: 5px auto;
    font-weight: 300;
  }
  .cardCategorie .btnPlayCategorie {
    font-family: "Poppins", sans-serif !important;
    background: #04c011;
    border-radius: 16px;
    font-weight: 500;
    width: 130px;
    height: 31px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0px auto;
    font-size: 13px;
    letter-spacing: -0.13px;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    margin: 0px auto;
    text-transform: uppercase;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .btn-charger-plus {
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
    background: #fff;
    border-radius: 25px;
    color: #2e3c5e;
    font-weight: 600;
    font-size: 16px;
    border: 2px solid #bec2cd;
    text-transform: uppercase;
    height: 40px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
  }

  .btn-charger-plus:hover {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    height: 40px;
    width: 90%;
  }
  .appFooter {
    display: block;
    padding: 0px;
  }

  .questionDetails {
    margin-left: 10px;
    min-height: 80vh;
  }
}
