body {
  background-color: #ffffff;
}
.TopNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff;
}
button:focus {
  outline: none;
}

.dropdown-menu {
  margin: 0.125rem -10px 0;
}

.dropdown-menu:before {
  left: 140px;
}
.navLinkLanguage {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e6e8;
  width: 113px;
  padding: 2px 18px;
  font-weight: 600;
}
.iconSelect {
  width: 15px;
  margin: 0px 10px;
  cursor: pointer;
}

.currentLanguage {
  margin: 0px 0px;
  cursor: pointer;
  text-transform: uppercase;
}

.text-switch-off {
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 500 !important;
  letter-spacing: -0.13px !important;
  padding: 5px 0px !important;
  width: 72px !important;
  margin-left: -37px !important;
}

.text-switch-on {
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 500 !important;
  letter-spacing: -0.13px !important;
  padding: 5px 12px !important;
  width: 120px !important;
}

.contentHeaderRight {
  display: flex;
  align-items: center;
}

.linkHeader {
  cursor: pointer;
}
.menuHeader {
  display: none;
}
.appLogo {
  cursor: pointer;
  position: relative;
}

.appFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px;
  margin: 50px 0px;
}

.linkFooter {
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}
.titleFooter {
  font-weight: 600;
  font-size: 14px;
  color: #5c91c9;
  margin-top: -8px;
}

.textFooter {
  font-size: 14px;
  font-weight: 600;
  color: #787e86;
}
.iconShare {
  margin-left: -4px;
  margin-top: -3px;
}
.iconShare img {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .linkHeader {
    display: none;
  }

  .SelectLanguage {
    display: none;
  }

  .switchHeader {
    display: none;
  }
  .menuHeader {
    display: block;
  }

  .menuHeader .dropdown-menu {
    margin: 0.125rem -110px 0;
  }
  .TopNavbar {
    padding: 10px;
  }
  .menuHeader .dropdown-menu:before {
    left: 10px;
  }
  .containerCategorie {
    padding: 20px 0px !important;
    margin: 0px auto !important;
    width: 690px !important;
    justify-content: start !important;
  }

  .smallTitleHome {
    font-size: 14px !important;
    line-height: 18px !important;
    padding: 0px 35px;
  }
  .containerHome hr {
    margin-top: 0px;
  }
}
